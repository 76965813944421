var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "hello"
  }, [_c('StreamBarcodeReader', {
    on: {
      "decode": function (a, b, c) {
        return _vm.onDecode(a, b, c);
      },
      "loaded": function () {
        return _vm.onLoaded();
      }
    }
  }), _vm._v(" Input Value: " + _vm._s(_vm.text || "Nothing") + " ")], 1)]), _c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }