<template>
  <b-card>
<b-row>
<b-col xl="6" lg="6" md="6">
  
  <div class="hello">
      <StreamBarcodeReader
      @decode="(a, b, c) => onDecode(a, b, c)"
      @loaded="() => onLoaded()"
      ></StreamBarcodeReader>
      Input Value: {{ text || "Nothing" }}
    </div>
  </b-col>
  <b-col xl="6" lg="6" md="6"></b-col>
  </b-row>
  </b-card>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import {
  BFormCheckbox,
  BTable,
  BFormFile,
  VBTooltip,
  BAvatar,
  BAlert,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BCardText,BCard,
  BFormDatepicker,
  BFormSelectOption,
} from "bootstrap-vue";

export default {
  name: "HelloWorld",
  components: {
    StreamBarcodeReader,
  BFormCheckbox,
  BTable,
  BFormFile,
  VBTooltip,
  BAvatar,
  BAlert,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BCardText,BCard,
  BFormDatepicker,
  BFormSelectOption,
  },
  data() {
    return {
      text: "",
      id: null,
      loaded:false,
    };
  },
  props: {
    msg: String,
  },
  methods: {
    onDecode(a, b, c) {
      // console.log(a, b, c);
      this.text = a;
      this.displaySuccess({
        message: 'Berhasil Guys'
      })
      // if (this.id) clearTimeout(this.id);
      // this.id = setTimeout(() => {
      //   if (this.text === a) {
      //     this.text = "";
      //   }
      // }, 5000);
    },
    onLoaded() {
      console.log("load");
    },
  },
};
</script>
<style scoped>
</style>
